.App {
  max-width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.6;
  color: #111111;
  background: #f0f0f0; /* Changed from #fafafa to a slightly darker shade */
}

html, body {
  background-color: #f0f0f0; /* Added this to ensure the entire page has the same background */
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.header {
  text-align: left;
  padding: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(240, 240, 240, 0.8); /* Changed from rgba(250, 250, 250, 0.8) */
  backdrop-filter: blur(8px);
  z-index: 1000;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
}

.header h1 {
  margin: 0;
  color: #111111;
  font-size: 1.5em;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.header nav ul {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.header nav ul li {
  margin-right: 25px;
}

.header nav ul li a {
  text-decoration: none;
  color: #555555;
  font-weight: 400;
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 5px;
  cursor: pointer;
}

.header nav ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #111111;
  transition: width 0.3s ease;
}

.header nav ul li a:hover {
  color: #111111;
}

.header nav ul li a:hover::after {
  width: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 140px 40px 40px;
  max-width: 1140px;
  margin: 0 auto;
  background-color: #f0f0f0; /* Added this to ensure content area has the same background */
}

.profile-section {
  width: 50%;
  text-align: left;
  margin-bottom: 40px;
  padding-top: 20px;
  position: relative;
}

@media (max-width: 768px) {
  .profile-section {
    width: 100%;
  }
}

.profile-image img {
  width: 250px;
  height: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.3s ease;
}

.profile-image img:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.social-links {
  margin-top: 25px;
}

.social-links a {
  font-size: 22px;
  margin-right: 20px;
  color: #555555;
  transition: all 0.3s ease;
}

.social-links a:hover {
  color: #111111;
}

.sections-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.section {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  transition: none;
  cursor: default;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 40px;
}

.section:last-child {
  border-bottom: none;
}

.section:hover {
  box-shadow: none;
}

.section h2 {
  color: #111111;
  margin-top: 0;
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
}

.section p {
  margin-top: 0;
}

@media (max-width: 768px) {
  .App {
    padding: 0;
  }

  .header {
    padding: 15px;
  }

  .header h1 {
    font-size: 1.3em;
  }

  .header nav ul {
    flex-wrap: wrap;
  }

  .header nav ul li {
    margin-right: 15px;
    margin-bottom: 10px;
  }

  .profile-image img {
    width: 200px;
  }

  .section {
    padding: 0;
    padding-bottom: 30px;
  }

  .sections-container {
    gap: 30px;
  }

  .main-content {
    padding: 120px 20px 20px;
  }
}

.writings-nav {
  padding: 20px;
}

.writings-list {
  list-style-type: none;
  padding: 0;
}
.writings-list li {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  color: #111; /* Darker color for the title */
  font-weight: 600; /* Made slightly bolder */
  font-size: 1.2em;
  padding: 10px 0;
  transition: color 0.3s ease, padding-left 0.3s ease;
  position: relative;
  cursor: pointer;
}

.writings-list li a {
  display: block;
  text-decoration: none;
  color: inherit;
}

.writings-list li span {
  color: #999; /* Lighter gray for the date */
  font-weight: 400;
  font-size: 0.75em; /* Slightly smaller */
  margin-top: 4px; /* Add some spacing between title and date */
}

.writings-list li:hover {
  padding-left: 25px;
  /* Removed color change on hover since title is already dark */
}

.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  background-color: #f0f0f0; /* Added this to ensure article container has the same background */
}

/* Target all images in markdown content */
.markdown img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 2rem auto;
}